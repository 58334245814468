<script>
import { layoutMethods,classesMethods,studentClassMethods,SessionsDatesMethods,ClassesDaysMethods,PointageMethods } from "@/state/helpers";
import QrcodeVue from 'qrcode.vue'
/**
 * Right-sidebar 
 */
export default {
  components: {
       "qrcode-vue": QrcodeVue,
      },
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"],
      },
      CurrentClass :null,
      TodaysClasses:[],
      Student:null,
      AlreadyScanned : false,
    };
  },
  created(){
    this.Student = JSON.parse(localStorage.getItem("user"));
    this.getStudentClass({ id:this.Student.id})
    this.getClasses({ id:this.Student.schoolId})
    this.getClassesDays({ id:this.Student.schoolId})
    this.getSessionsDates({ id:this.Student.schoolId})
    this.GetPointage({ id:this.Student.id})
  },
  computed: {
    sessionsdates() {
      return this.$store ? this.$store.state.sessionsdates.SessionsDates : null;
    },
    classesdays() {
      return this.$store ? this.$store.state.classesdays.ClassesDays : null;
    },
    studentsclass() {
      return this.$store ? this.$store.state.studentsclass.StudentsClass : null;
    }, 
    classes() {
      return this.$store ? this.$store.state.classes.Classes : null;
    }, 
    pointage() {
      return this.$store ? this.$store.state.pointage.Pointage : null;
    },
  },
  watch:{
    sessionsdates(val){
      if(val!=null){
        this.getTodaysClasses()
      }
    },
    pointage(val){
      if(val != null){
        val.filter(x=>{
          var today = new Date();
          let classdate = new Date(x.date);
       
        let differencedates = this.CalcDays(classdate,today);
        if(differencedates>= - 60 && differencedates <= 60){
          this.AlreadyScanned =true;
          
        }
        })
      }
    },
  },
  methods: {
    handleRightBarClick() {
      this.$parent.hideRightSidebar();
    },
    middleware(event) {
      if (event.target.classList)
        return !event.target.classList.contains("toggle-right");
    },
    ...ClassesDaysMethods,
    ...layoutMethods,
    ...classesMethods,
    ...studentClassMethods,
    ...SessionsDatesMethods,
    ...PointageMethods,
    hide() {
      this.$parent.toggleRightSidebar();
    },
  
    getTodaysClasses() {
      var today = new Date();
      var currentdaytomp =(today.getMonth() + 1) +  "/" +today.getDate() + "/" +today.getFullYear();

      let subclasses = []
      let substudentsclasses = this.studentsclass.filter(x=> x.studentid == this.Student.id)
      substudentsclasses.forEach(el=>{
        let susubclass = this.classes.filter((x) =>  x.id == el.classid);
        if(susubclass.length>0){
          subclasses.push(susubclass[0])
        }
      })
      

      let arr = [];
      subclasses.forEach(element => {
        let arry = this.sessionsdates.filter((x) => x.classid == element.id && this.Compadedated(today,x.date) );
        arr= arr.concat(arry);
      });
      let arrdays = [];

      arr.forEach(x=>{
        let arrydays = this.classesdays.filter(el=>(el.schoolid.toString() == x.schoolid.toString() && el.classid.toString() == x.classid.toString() && el.day == this.getDayModal(x.title.toLowerCase())));
        arrdays= arrdays.concat(arrydays);
      })

      const ids = arrdays.map(o => o.id)
      const filtered = arrdays.filter(({id}, index) => !ids.includes(id, index + 1))
      this.TodaysClasses = filtered;

      filtered.forEach(x=>{
        let classdate = new Date(currentdaytomp +" "+x.startat);
       
        let differencedates = this.CalcDays(classdate,today);
        if(differencedates>= - 45 && differencedates <= 45){
         // schooldid  classeid  teacherid  dure
         //   !!x+x!x+x!!schooldid+classeid+teacherid+studentid!!x+x!x+x!!
         //   !!x+x!x+x!!12+15+55+12!!x+x!x+x!!

          this.CurrentClass = "fff!"+x.schoolid+"+"+x.classid+"+"+this.Student.id+"+fff!";
          
        }
      })
    },
    Compadedated(date1,date2){
      let classdate1= new Date(
        (date1.getMonth() + 1) + 
        "/" +
        date1.getDate() +
        "/" +
        date1.getFullYear() +" 00:00");
      let datesub = date2.split('/')
      let classdate2= new Date(datesub[1]+"/"+datesub[0]+"/"+datesub[2]+"/" +" 00:00");
      if(this.CalcDaysIndays(classdate1, classdate2) == 0){
        return true;
      }else{
        return false;
      }
    },
    CalcDays(datefrom, dateto) {
      let difference = datefrom.getTime() - dateto.getTime();
      return Math.ceil((difference / 1000 )/60);
    },
    CalcDaysIndays(datefrom, dateto) {
      let difference = datefrom.getTime() - dateto.getTime();
      return Math.ceil((difference  / (1000 * 60 * 60 * 24)));
    },
    getDayModal(val) {
      var answer = "";
      switch (val) {
        case "samedi":
          answer = 0;
          break;
        case "dimanche":
          answer = 1;
          break;
        case "lundi":
          answer = 2;
          break;
        case "mardi":
          answer = 3;
          break;
        case "mercredi":
          answer = 4;
          break;
        case "jeudi":
          answer = 5;
          break;
        case "vendredi":
          answer = 6;
          break;
        default:
          answer = -1;
      }
      return answer;
    },
  },
};
</script>

<template>
  <div>
    <!-- Right Sidebar -->
    <div v-click-outside="config" class="right-bar">
      <div data-simplebar class="h-100">
        <div class="rightbar-title px-3 py-4">
          <a
            href="javascript:void(0);"
            class="right-bar-toggle float-right"
            @click="hide"
          >
            <i class="mdi mdi-close noti-icon"></i>
          </a>
          <h5 class="m-0" @click="getTodaysClasses()">Pointage</h5>
        </div>
        <div class="p-3">
        
          <hr class="mt-4 mb-4 py-4" />
          <div class="mt-4" v-if="CurrentClass != null && AlreadyScanned== false">
              <qrcode-vue :value="CurrentClass" :size="200" level="H" :renderas="'canvas'" background="#ffffff" />
            </div> 
            <div v-else>
              <h5>Pas des séances pour le moment</h5>
            </div>
        </div>
       
       
      </div>
     
    </div>
    
    <div class="rightbar-overlay"></div>
  </div>
</template>